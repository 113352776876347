import { useEffect, useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SimpleReactValidator from "simple-react-validator";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Moment from "moment-timezone";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

/* eslint-disable */
function Add(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [pauseSeries, setPauseSeries] = useState(false);
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [envelopFrom, setEnvelopFrom] = useState("");
  const [displayFrom, setDisplayFrom] = useState("");
  const [testEmail, setTestEmail] = useState("");
  // const [subscribersData, SetSubscribersData] = useState([]);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [valueDate, setValueDate] = useState(Moment());
  const [dateFrom, setDateFrom] = useState(12);
  const [dateTo, setDateTo] = useState(12);
  const [dateAt, setDateAt] = useState(12);
  const [templateBody, setTemplateBody] = useState(props.body);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [sendMode, setSendMode] = useState("0");
  // const [sendModeData, setSendModeData] = useState({});
  const [recipients, setRecipients] = useState(0);
  const [contentText, setContentText] = useState("");
  const [mimeType, setMimeType] = useState(0);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [catalogs, setCatalogs] = useState([]);
  const [segmentsRows, setSegmentsRows] = useState(null);
  const [triggers, setTriggers] = useState(null);
  const emailEditorRef = useRef(null);
  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);

  const clearVariables = () => {
    setName("");
    setSubject("");
    setPauseSeries(false);
    setEnvelopFrom("");
    setDisplayFrom("");
    setValueDate(Moment());
    setContentText("");
    setMimeType(0);
    setTemplateBody("");
    setHtmlPreview("");
    setSubjectPreview("");
    // setArticles("");
    // setAds("");
    simpleValidator.current.hideMessages();
    props.onDone();
    closeModal();
  };

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const handlePreview = () => {
    setIsLoading(true);

    options.data = JSON.stringify({
      subject: subject,
      body: editorRef.current.getContent(),
    });
    client
      .request(options)
      .then((response) => {
        setHtmlPreview(response.body);
        setSubjectPreview(response.subject);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      const timeZone = localStorage.getItem("timeZone");
      const options = {
        method: "POST",
        url: `broadcasts`,
        headers: {
          "content-type": "application/json",
        },
      };
      let errors = false;

      if (!errors) {
        setIsLoading(true);
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
          const html = data.html;
          const design = data.design;

          const sendModeJson = {
            mode: sendMode,
            hourFrom: dateFrom,
            hourTo: dateTo,
            hourSpecific: dateAt,
          };

          options.data = JSON.stringify({
            bro_id: 0,
            bro_emc_id: 0,
            name: name,
            displayfrom: displayFrom,
            fromaddress: envelopFrom,
            subject: subject,
            html: html,
            json: JSON.stringify(design),
            pauseStandard: pauseSeries ? true : false,
            date: valueDate,
            bro_segments: segmentsRows,
            bro_sendMode: sendModeJson,
            recipient: testEmail,
            trigger_events: triggers,
          });

          client
            .request(options)
            .then((response) => {
              setIsLoading(false);
              clearVariables();
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error);
            });
        });
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const sendTest = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };

      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          bro_id: 0,
          bro_emc_id: 0,
          name: "",
          displayfrom: displayFrom,
          fromaddress: envelopFrom,
          subject: subject,
          html: html,
          pauseStandard: false,
          date: Moment(),
          bro_segments: null,
          recipient: testEmail,
          trigger_events: null,
        });

        console.log(options.data);

        client
          .request(options)
          .then((response) => {
            setOpenTestSB(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onReady = (unlayer) => {
    unlayer.registerCallback("previewHtml", async function (params, done) {
      const options = {
        method: "POST",
        url: `emailCopy/Preview`,
        headers: {
          "content-type": "application/json",
        },
      };
      options.data = JSON.stringify({
        subject: subject,
        body: params.html,
      });
      client
        .request(options)
        .then((response) => {
          setSubjectPreview(response.subject);
          done({
            html: response.body,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    });
  };

  // useEffect(() => {
  //   getCatalogs();
  // }, []);

  useEffect(() => {
    if (segmentsRows != null) {
      // console.log("calculateRecipients");
      options.method = "POST";
      options.url = `broadcasts/calculateRecipients`;
      options.data = segmentsRows;

      client
        .request(options)
        .then((response) => {
          setRecipients(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(segmentsRows);
    }
    //calculateRecipients();
  }, [segmentsRows]);

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };
  const handleChangeDisplayFrom = (event) => {
    setDisplayFrom(event.target.value);
  };
  const handleChangeEnvelopFrom = (event) => {
    setEnvelopFrom(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeText = (event) => {
    setContentText(event.target.value);
  };
  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };
  const handlePauseSeries = () => {
    setPauseSeries(!pauseSeries);
  };

  const onChangeSegment = (value) => {
    setSegmentsRows(value);
  };
  const onChangeTriggers = (value) => {
    setTriggers(value);
  };

  const handleChangeMode = (event) => {
    setSendMode(event.target.value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Create a new broadcast
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Broadcast name"
                  fullWidth
                  value={name}
                  onChange={handleChangeName}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Subject"
                  fullWidth
                  value={subject}
                  onChange={handleChangeSubject}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("subject", subject, "required")}
                </MDBox>
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Display From example: Blog news"
                  fullWidth
                  value={displayFrom}
                  onChange={handleChangeDisplayFrom}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("display from", displayFrom, "required")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Envelop From"
                  fullWidth
                  value={envelopFrom}
                  onChange={handleChangeEnvelopFrom}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("envelop from", envelopFrom, "required|email")}
                </MDBox>
              </MDBox>
              {false && (
                <MDBox display="flex" justifyContent="left">
                  <Tooltip
                    id="descriptionHelp"
                    title={
                      <MDTypography color={"white"}>
                        Standard series will be paused for the entire day of the broadcast scheduled
                        date
                      </MDTypography>
                    }
                  >
                    <MDTypography variant="h6" fontWeight="medium">
                      <IconButton color="info">
                        <HelpOutlineIcon />
                      </IconButton>
                      Pause standard series
                    </MDTypography>
                  </Tooltip>
                  <Switch checked={pauseSeries} onChange={handlePauseSeries} />
                </MDBox>
              )}
              <MDBox display="flex" justifyContent="left">
                <MDBox mt={3} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      required
                      renderInput={(props) => <TextField {...props} />}
                      label="Select the date to send"
                      value={valueDate}
                      onChange={(newValue) => {
                        setValueDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </MDBox>
              </MDBox>
              <MDBox display="flex" justifyContent="left">
                <MDBox mt={3} mb={3}>
                  <FormControl>
                    <MDTypography variant="h5" fontWeight="medium" mt={1}>
                      Send mode
                    </MDTypography>
                    <RadioGroup value={sendMode} onChange={handleChangeMode}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        //label="Mailsense should send when people engage."
                        label="Sendpad should send when people engage."
                      />
                      <FormControlLabel value="2" control={<Radio />} label="Send everything at" />
                      <MDInput
                        InputProps={{ inputProps: { min: 1, max: 24 } }}
                        type="number"
                        value={dateAt}
                        onChange={(e) => setDateAt(e.target.value)}
                      />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <Grid>
              <MDInput
                onChange={(event) => handleSendTest(event)}
                label="e-mails to test"
                style={{ marginBottom: 10, width: "100%" }}
              />
              <MDButton
                autoFocus
                variant="gradient"
                color="success"
                onClick={sendTest}
                disabled={isLoading}
              >
                Send
              </MDButton>
            </Grid>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          autoFocus
          onClick={clearVariables}
          disabled={isLoading}
          variant="gradient"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={handleSave}
          disabled={isLoading}
          autoFocus
          variant="gradient"
          color="info"
        >
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sending successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default Add;
