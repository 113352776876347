import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            fontSize={size.sm}
            px={1.5}
          >
            &copy; {new Date().getFullYear()}
            <Link href={href} target="_blank">
              <MDTypography variant="button" fontWeight="medium">
                &nbsp;{name}&nbsp;
              </MDTypography>
            </Link>
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            {renderLinks()}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
switch (process.env.REACT_APP_PLNAME) {
  case "sendpad":
    Footer.defaultProps = {
      company: {
        href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
        name: process.env.REACT_APP_SITE_TITLE,
      },
      links: [
        {
          href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
          name: process.env.REACT_APP_SITE_TITLE,
        },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/#whatweoffer`, name: "About Us" },
        { href: `https://support.${process.env.REACT_APP_MAIN_DOMAIN}`, name: "Contact Us" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/privacy-policy`, name: "Privacy" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/terms-of-use`, name: "Terms" },
      ],
    };
    break;
  case "pixapage":
    Footer.defaultProps = {
      company: {
        href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
        name: process.env.REACT_APP_SITE_TITLE,
      },
      links: [
        {
          href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
          name: process.env.REACT_APP_SITE_TITLE,
        },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "About Us" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/contact`, name: "Contact Us" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "Privacy" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "Terms" },
      ],
    };
    break;
  default:
    Footer.defaultProps = {
      company: {
        href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
        name: process.env.REACT_APP_SITE_TITLE,
      },
      links: [
        {
          href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/`,
          name: process.env.REACT_APP_SITE_TITLE,
        },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "About Us" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}/contact`, name: "Contact Us" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "Privacy" },
        { href: `https://${process.env.REACT_APP_MAIN_DOMAIN}`, name: "Terms" },
      ],
    };
    break;
}

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
