import { Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";

/*eslint-disable */
export default function ConfirmationAlert(props) {
  const [open, setOpen] = useState(props.openModal);
  return (
    <Dialog open={props.openModal}>
      <DialogContent>
        <MDTypography>{props.title}</MDTypography>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton autoFocus onClick={props.onClose} variant="gradient" color="secondary">
          No
        </MDButton>
        <MDButton autoFocus variant="gradient" color="error" onClick={props.onYes}>
          {props.yesText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
